import $ from 'jquery';
import SmoothScroll from 'smooth-scroll';
export default {
  init() {
    var scroll = new SmoothScroll('a[href*="#"]', {
      speed: 300
    });
    
    $('.loadmore').click(function(){
      var button = $(this),
      data = {
        'action': 'loadmore',
        'query': loadmore_params.posts,
        'page' : loadmore_params.current_page
      };
   
      $.ajax({ 
        url : loadmore_params.ajaxurl,
        data : data,
        type : 'POST',
        beforeSend : function ( xhr ) {
          button.text('Loading...');
        },
        success : function( data ){
          if( data ) { 
            button.text( 'More posts' ).prev().before(data); // insert new posts
            loadmore_params.current_page++;
   
            if ( loadmore_params.current_page == loadmore_params.max_page ) 
              button.remove(); // if last page, remove the button
   
            // you can also fire the "post-load" event here if you use a plugin that requires it
            // $( document.body ).trigger( 'post-load' );
          } else {
            button.remove(); // if no data, remove the button as well
          }
        }
      });
    });
    // JavaScript to be fired on all pages

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
